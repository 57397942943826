


















































import api from "@/api/index";
import { Vue, Component, Watch } from "vue-property-decorator";
import { SchoolContactDto, SelectListItem } from "@/api/appService";
import { getSchoolNameBySchoolId } from "@/views/studentAid/school/contact/list.vue";


@Component({
  name: "ContactDetail",
  components: {}
})
export default class ContactDetail extends Vue {
  labelStyle = {
    fontSize: "16px"
  };
  contactInfo: SchoolContactDto = {};
  schoolName = "";
  genderList: SelectListItem[] = [];
  loading = true;

  async created() {
    //此处写根据联系人id获取该联系人的信息的代码
    this.contactInfo = await this.getContactInfoById();
    this.genderList = await this.getGenderList();
    const { schoolName } = await getSchoolNameBySchoolId(Number(this.contactInfo.schoolId));
    this.schoolName = schoolName!;
    this.loading = false;
  }

  getContactInfoById() {
    return api.school.getContact({
      id: Number(this.$route.params.contactId)
    });
  }

  getGenderList() {
    return api.enumService.getValues({ typeName: "Sex" });
  }

  back() {
    this.$router.go(-1);
  }
}
